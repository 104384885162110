import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { getSearchList } from "../../services/AdminService";

const SeachBox = (props: { parent: any; page_size: number }) => {
  const [queryValue, setqueryValue] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (queryValue.trim() === "") {
          props.parent({ results: [], count: 0 });
        } else {
          getSearchList(queryValue, props.page_size).then((response) => {
            if (response) {
              // props.parent(response.results);
              props.parent(response);
            }
          });
        }
      }}
      className=" d-md-flex"
    >
      <Form.Group controlId="formBasicEmail">
        <Form.Control
          type="search"
          value={queryValue}
          placeholder="Search"
          onChange={(e) => {
            setqueryValue(e.target.value);
            if (e.target.value === "") {
              props.parent({ results: [], count: -1 });
            }
          }}
          style={{ backgroundColor: "white" }}
        />
      </Form.Group>
    </form>
  );
};

export default SeachBox;
